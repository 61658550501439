import { ACCOUNT_URL, API_BASE, PORTAL_URL } from '@/constants/index.js'

const loggedIn = ref(false)
const isInternal = ref(false)
const accountUrl = ref(ACCOUNT_URL)

export function useAuth () {
	async function getAuth () {
		// TODO tsify
		const authResponse = await $fetch(`${API_BASE}/auth`, { credentials: 'include' })
		if (!authResponse) {
			loggedIn.value = false
			isInternal.value = false
			accountUrl.value = ACCOUNT_URL
			return
		}
		loggedIn.value = !!authResponse.emailId
		isInternal.value = !!authResponse.siteAdmin
		accountUrl.value = isInternal.value ? PORTAL_URL : ACCOUNT_URL
	}
	return {
		getAuth,
		loggedIn,
		accountUrl,
		isInternal
	}
}
